/**
 * File: /src/components/PrivacyPolicy.js
 * Package: Web
 * Project: payments
 * File Created: 07 January 2019 2:55 PM
 * Author: Justin Li (jli@arising.net)
 * -----
 */


import React from 'react';

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div style={{ height: '400px', overflow: 'auto' }}>
        <div>
          This Privacy Policy describes how your personal information is collected, used, and shared when you visit or
          make a purchase from ArisingPay.com (the "Site").
        </div>
        <section>
          <h4>PERSONAL INFORMATION WE COLLECT</h4>
          <div>
            When you visit the Site, we automatically collect certain information about your device, including
            information about your web browser, IP address, time zone, and some of the cookies that are installed on
            your device. Additionally, as you browse the Site, we collect information about the individual web pages or
            products that you view, what websites or search terms referred you to the Site, and information about how
            you interact with the Site. We refer to this automatically-collected information as "Device Information."
            We collect Device Information using the following technologies:
            <ul>
              <li>
                <b>"Cookies"</b> are data files that are placed on your device or computer and often include an
                anonymous unique identifier. For more information about cookies, and how to disable cookies,
                visit <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org</a>.
              </li>
              <li>
                <b>"Log files"</b> track actions occurring on the Site, and collect data including your IP
                address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
              </li>
              <li>
                <b>"Web beacons," "tags," and "pixels"</b> are electronic files used to record information
                about how you browse the Site.
              </li>
            </ul>
            Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain
            information from you, including your name, billing address, shipping address, payment information (including
            credit card numbers, ACH), email address, and phone number. We refer to this information as "Order
            Information."
            When we talk about "Personal Information" in this Privacy Policy, we are talking both about Device
            Information and Order Information.

          </div>
        </section>
        <section>
          <h4>HOW DO WE USE YOUR PERSONAL INFORMATION?</h4>
          <div>
            We use the Order Information that we collect generally to fulfill any orders placed through the Site
            (including processing your payment information, arranging for shipping, and providing you with invoices
            and/or order confirmations). Additionally, we use this Order Information to:
            Communicate with you;
            Screen our orders for potential risk or fraud; and
            When in line with the preferences you have shared with us, provide you with information or advertising
            relating to our products or services.
            We use the Device Information that we collect to help us screen for potential risk and fraud (in particular,
            your IP address), and more generally to improve and optimize our Site (for example, by generating analytics
            about how our customers browse and interact with the Site, and to assess the success of our marketing and
            advertising campaigns).
          </div>
        </section>
        <section>
          <h4>SHARING YOUR PERSONAL INFORMATION</h4>
          <div>
            We share your Personal Information with third parties to help us use your Personal Information, as described
            above. For example, we use Stripe.com to process credit card transactions---you can read more about how
            Stripe uses your Personal Information here: https://stripe.com/us/privacy. We also use Google Analytics to
            help us understand how our customers use the Site--you can read more about how Google uses your Personal
            Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics
            here: https://tools.google.com/dlpage/gaoptout.
            Finally, we may also share your Personal Information to comply with applicable laws and regulations, to
            respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise
            protect our rights.
          </div>
        </section>
        <section>
          <h4>BEHAVIOURAL ADVERTISING</h4>
          <div>
            As described above, we use your Personal Information to provide you with targeted advertisements or
            marketing communications we believe may be of interest to you. For more information about how targeted
            advertising works, you can visit the Network Advertising Initiative's ("NAI") educational page at
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
            <div>
              You can opt out of targeted advertising by:
              <ul style={{ listStyleType: 'none' }}>
                <li>
                  FACEBOOK - https://www.facebook.com/settings/?tab=ads
                </li>
                <li>
                  GOOGLE - https://www.google.com/settings/ads/anonymous
                </li>
                <li>
                  BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                </li>
              </ul>
            </div>
            Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance's
            opt-out portal at: http://optout.aboutads.info/.
          </div>
        </section>
        <section>
          <h4>DO NOT TRACK</h4>
          <div>
            Please note that we do not alter our Site's data collection and use practices when we see a Do Not Track
            signal from your browser.
          </div>
        </section>
        <section>
          <h4>YOUR RIGHTS</h4>
          <div>
            If you are a European resident, you have the right to access personal information we hold about you and to
            ask that your personal information be corrected, updated, or deleted. If you would like to exercise this
            right, please contact us through the contact information below.

            Additionally, if you are a European resident we note that we are processing your information in order to
            fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise
            to pursue our legitimate business interests listed above. Additionally, please note that your information
            will be transferred outside of Europe, including to Canada and the United States.
          </div>
        </section>
        <section>
          <h4>DATA RETENTION</h4>
          <div>
            When you place an order through the Site, we will maintain your Order Information for our records unless and
            until you ask us to delete this information.
          </div>
        </section>
        <section>
          <h4>CHANGES</h4>
          <div>
            We may update this privacy policy from time to time in order to reflect, for example, changes to our
            practices or for other operational, legal or regulatory reasons.
          </div>
        </section>
        <section>
          <h4>CONTACT US</h4>
          <div>
            For more information about our privacy practices, if you have questions, or if you would like to make a
            complaint, please contact us by e-mail at <a
            href="mailto: support@arising.net"><b>support@arising.net</b></a> or
            by mail using the details provided below:

            <div className="address">
              <div>Arising Group, Inc</div>
              <div>3050 Whitestone Expy Suite A302,</div>
              <div>Flushing, NY, 11354</div>
              <div>United States</div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}


export default PrivacyPolicy;