/*
 * File: \src\pages\homepage\index.js
 * Project: web
 * File Created: Tuesday, 24th March 2020 12:21:54 am
 */

import loadable from "@loadable/component";

const Homepage = loadable(() => import("./Homepage"));

export default Homepage;
