/**
 * File: /src/components/Loading.js
 * Package: Web
 * Project: payments
 * File Created: 16 November 2018 9:39 AM
 * Author: Justin Li (jli@arising.net)
 * -----
 */

import React from 'react';
import { Spin } from 'antd';

export default () => (
  <div className="spinnerContainer">
    <Spin size="large" className="spinner" />
  </div>
);
