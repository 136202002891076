/**
 * File: renderForError.js
 * Project: payments
 * File Created: 16 November 2018 2:19 PM
 * Author: Justin Li (jli@arising.net)
 * -----
 */

import { branch, renderComponent } from "recompose";

export const renderForError = (component, propName = 'data') =>
  branch(
    props => props[propName] && props[propName].error,
    renderComponent(component),
  );

export default renderForError;
