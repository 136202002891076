import store from 'store';
import ReactGA from 'react-ga';
import React from 'react';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { setContext } from 'apollo-link-context';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ConnectContainer from '../containers/Connect';
import OrganizationContainer from '../containers/organization/theme';
import DonorLayout from './donor';
import Footer from '../components/Footer';
import NotFoundPage from '../pages/notFound';
import Homepage from '../pages/homepage';

import '../less/App.less';


if (process.env.REACT_APP_GA_KEY) {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY, {
    debug: process.env.NODE_ENV === 'development',
  });

  ReactGA.pageview(window.location.pathname + window.location.search);
}

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI
});

const authLink = setContext((operation, { headers }) => {
  const token = store.get('token');

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      }
    }
  }

  return {
    headers,
  }
});


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


class App extends React.Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/connect" component={ConnectContainer} />
            <Route path="/donor" component={DonorLayout} />
            <Route path="/:shortName" component={OrganizationContainer} />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
        <Footer />
      </ApolloProvider>
    );
  }
}

export default App;
