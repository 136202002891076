import { branch, renderComponent } from "recompose";

/**
 * File: renderWhileLoading.js
 * Project: payments
 * File Created: 16 November 2018 2:18 PM
 * Author: Justin Li (jli@arising.net)
 * -----
 */

const renderWhileLoading = (component, propName = 'data') =>
  branch(
    props => props[propName] && props[propName].loading,
    renderComponent(component),
  );


export default renderWhileLoading;
