/**
 * File: /src/containers/connect.js
 * Package: Web
 * Project: payments
 * File Created: 27 November 2018 4:42 PM
 * Author: Justin Li (jli@arising.net)
 * -----
 */

import qs from "query-string";
import React from "react";
import { Redirect } from "react-router-dom";
import Error from "../components/Error";
import TokenPage from "../pages/Token";
import Loading from "../components/Loading";
import { graphql } from "react-apollo";
import connectMutation from "../gql/organization/mutation/connect";
import renderWhileLoading from "../components/renderWhileLoading";
import organizationWithConnect from "../gql/organization/query/organizationWithConnect";
import { compose, branch, renderComponent, withProps } from "recompose";
import renderForError from "../components/renderForError";

const Connect = compose(
  // convert query params to props
  withProps(props => {
    const params = qs.parse(props.location.search);
    return {
      state: params.state,
      code: params.code,
      error: params.error,
      error_description: params.error_description
    };
  }),
  // have to have state and code in props
  branch(props => {
    return !props.state || !props.code;
  }, renderComponent(Error)),
  // display error info from Stripe
  branch(
    props => {
      return !!props.error;
    },
    renderComponent(props => {
      return <Error message={props.error_description} />;
    })
  ),
  // fetch Organization info
  graphql(organizationWithConnect, {
    options: props => {
      const { state } = props;
      return {
        variables: { organizationInput: { _id: state } }
      };
    }
  }),
  renderWhileLoading(Loading),
  renderForError(Error),
  // add redirect url to props
  withProps(props => {
    const {
      data: { organization }
    } = props;
    return {
      redirect: `/${organization.shortName}/dashboard`
    };
  }),
  // redirect if organization has already connected with Stripe
  branch(
    props => {
      const {
        data: { organization }
      } = props;
      return organization.isConnected;
    },
    renderComponent(props => <Redirect to={props.redirect} />)
  ),
  // load connect mutation without triggering
  graphql(connectMutation, {
    skip: props => {
      const {
        data: { organization }
      } = props;
      return organization.isConnected;
    },
    options: props => {
      const { code, state } = props;
      return {
        variables: { input: { code, state } }
      };
    }
  })
)(TokenPage);

export default Connect;
