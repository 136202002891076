/**
 * File: /src/components/Footer.js
 * Package: Web
 * Project: payments
 * File Created: 11 December 2018 2:57 PM
 * Author: Justin Li (jli@arising.net)
 * -----
 */

import React from 'react';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import { Row, Col, Modal } from 'antd';

class Footer extends React.Component {
  showTerms = (e) => {
    e.preventDefault();
    Modal.info({
      title: 'Terms of Service',
      content: <TermsOfService />,
      width: '50%',
      onOk() {
      },
    });
  };

  ShowPrivacyPolicy = (e) => {
    e.preventDefault();
    Modal.info({
      title: 'Arising Group Inc. Privacy Policy',
      content: <PrivacyPolicy />,
      width: '50%',
      onOk() {

      }
    })
  };

  render() {
    return (
      <div className="footerContainer">
        <Row>
          <Col xs={24}>
            <a href="https://arisingpay.com">Powered by ArisingPay</a>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <a href="/" onClick={this.showTerms}>Terms of Service</a> |
            <a href="/" onClick={this.ShowPrivacyPolicy}> Privacy Policy</a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Footer;