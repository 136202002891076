/**
 * File: /src/gql/organization/mutation/connect.js
 * Package: Web
 * Project: payments
 * File Created: 28 November 2018 10:17 AM
 * Author: Justin Li (jli@arising.net)
 * -----
 */

import gql from 'graphql-tag';

export default gql`
    mutation connect($input: connectInput!){
        connect(input:$input) {
            _id
            isConnected
        }
    }

`