/**
 * File: /src/gql/organization/query/organizationWithConnect.js
 * Package: Web
 * Project: payments
 * File Created: 26 November 2018 1:22 PM
 * Author: Justin Li (jli@arising.net)
 * -----
 */


import gql from 'graphql-tag';

export default gql`
    query organization($organizationInput: organizationInput!) {
        organization(input: $organizationInput) {
            _id
            name
            shortName
            email
            logo
            tel
            url
            isConnected
            currency
            amounts
            address{
                street1
                street1
                city
                state
                zipCode
            }
            theme{
                primary
                secondary
                text
                textSecondary
                heading
                layoutBodyBackground
                btnPrimaryBg
                layoutHeaderBackground
                borderColorBase
            }
        }
    }
`;
 