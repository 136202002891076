/**
 * File: /src/pages/Token.js
 * Package: Web
 * Project: payments
 * File Created: 27 November 2018 4:43 PM
 * Author: Justin Li (jli@arising.net)
 * -----
 */

import React from 'react';
import Error from '../components/Error';
import Loading from '../components/Loading';

class Token extends React.Component {
  state = {
    loading: true,
    error: null
  };

  componentDidMount() {
    const { mutate, redirect, history, } = this.props;
    mutate().then(() => {
      history.push(redirect);
    }).catch((err) => {
      this.setState({
        loading: false,
        error: err.message,
      });

    });
  }

  render() {
    const { loading, error } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Error message={error} />
    }

    return (
      <div>
        Connecting...
      </div>
    )
  }
}


export default Token;